<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading" :can-cancel="false" />
        <div v-if="isLive" class="alert alert-danger" style="text-align: center; font-weight: bold;" role="alert">
            You are Managing the Live Server Data
        </div>
        <CRow>
            <CCol sm="8">
                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-user"/><strong> {{ player.userName }} </strong>
                        <div class="card-header-actions">
                            <CBadge :color="$config.userClr(player.flags)">{{ $config.userTxt(player.flags) }}</CBadge>
                        </div>
                    </CCardHeader>
                    <CCardBody>
                        <dl class="row">
                            <dt class="col-sm-3">Username</dt>
                            <dd class="col-sm-9">{{ player.userName }}</dd>

                            <dt class="col-sm-3">Email</dt>
                            <dd class="col-sm-9">{{ player.email }}</dd>

                            <dt class="col-sm-3">Slots</dt>
                            <dd class="col-sm-9">{{ player.characterSlots }}</dd>

                            <dt class="col-sm-3">Key</dt>
                            <dd class="col-sm-9">{{ player.id }}</dd>

                            <dt class="col-sm-3">Updated</dt>
                            <dd class="col-sm-9">{{ player.updated | formatDate }}</dd>

                            <dt class="col-sm-3">Created</dt>
                            <dd class="col-sm-9">{{ player.created | formatDate }}</dd>
                            
                        </dl>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <slot name="header">
                            <CIcon name="cil-casino"/><strong> Characters </strong>
                        </slot>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable
                                :key="charDataKey"
                                :items="chars"
                                :fields="charFields"
                                :hover="hover"
                                pagination >

                            <template #location="{item}">
                                <td>
                                    <vue-json-pretty
                                            :path="'res'"
                                            :data="item.location">
                                    </vue-json-pretty>
                                </td>
                            </template>
                            <template #timePlayed="{item}">
                                <td class="py-2">{{ item.timePlayed | formatTimeSpan }}</td>
                            </template>
                            <template #updated="{item}">
                                <td class="py-2">{{ item.updated | formatDate }}</td>
                            </template>
                            <template #created="{item}">
                                <td class="py-2">{{ item.created | formatDate }}</td>
                            </template>
                            <template #manage="{item}">
                                <td class="py-2">
                                    <CButton
                                            color="primary"
                                            variant="outline"
                                            square
                                            size="sm"
                                            @click="manageChar(item.id)">
                                        Manage
                                    </CButton>
                                </td>
                            </template>
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <slot name="header">
                            <CIcon name="cil-casino"/><strong> Deleted Characters </strong>
                        </slot>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable
                                :key="charDataKey"
                                :items="delChars"
                                :fields="charDelFields"
                                :hover="hover"
                                pagination >

                            <template #location="{item}">
                                <td>
                                    <vue-json-pretty
                                            :path="'res'"
                                            :data="item.location">
                                    </vue-json-pretty>
                                </td>
                            </template>
                            <template #timePlayed="{item}">
                                <td class="py-2">{{ item.timePlayed | formatTimeSpan }}</td>
                            </template>
                            <template #updated="{item}">
                                <td class="py-2">{{ item.updated | formatDate }}</td>
                            </template>
                            <template #created="{item}">
                                <td class="py-2">{{ item.created | formatDate }}</td>
                            </template>
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import VueJsonPretty from 'vue-json-pretty'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import 'vue-json-pretty/lib/styles.css';

    export default {
        name: "Player",
        props: [ "id" ],
        components: {
            VueJsonPretty,
            Loading
        },
        data(){
            return {
                isLoading: false,
                hover: true,
                player: {},
                charDataKey: 0,
                chars: [],
                delChars: [],
                charFields: [
                    { key: "name", label: "Name" },
                    { key: 'worldId', label: 'World Id' },
                    { key: 'location', label: 'Location' },
                    { key: 'timePlayed', label: 'Time Played' },
                    { key: 'updated', label: 'Updated' },
                    { key: 'created', label: 'Created' },
                    { key: 'manage', label: '' },
                ],
                charDelFields: [
                    { key: "name", label: "Name" },
                    { key: 'worldId', label: 'World Id' },
                    { key: 'location', label: 'Location' },
                    { key: 'timePlayed', label: 'Time Played' },
                    { key: 'updated', label: 'Updated' },
                    { key: 'created', label: 'Created' }
                ],
            }
        },
        computed: {
            isLive: function () {
                return (localStorage.env === "live");
            }
        },
        methods: {
            getPlayer() {
                this.isLoading = true;

                this.$http.get(this.$config.gameapi + '/Player/' + localStorage.env + '/' + this.id, { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.player = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.getChars();
                });
            },
            getChars() {
                this.$http.get(this.$config.gameapi + '/Player/' + localStorage.env + '/' + this.id + '/chars', { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.chars = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function () {
                    this.getDeletedChars();
                });
            },
            getDeletedChars() {
                this.$http.get(this.$config.gameapi + '/Player/' + localStorage.env + '/' + this.id + '/chars/deleted', { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.delChars = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.isLoading = false;
                    this.charDataKey++;
                });
            },
            manageChar(id) {
                this.$router.push({ name: 'Character', params: { id: id } })
            }
        },
        created() {
            this.getPlayer();
        }
    }
</script>
